// exports.linkResolver = ({ node, key, value }) => (doc) => {
// // const linkResolver = ({ field, value, node}) => doc => {
//   switch (doc.type) {
//     case 'project':
//       return `/project/${doc.uid}`

//     default:
//       return '/'
//   }
// }

// module.exports = {
//   linkResolver(doc) {
//     console.log(doc)
//     if (doc.type === 'article') {
//       return `/article/${doc.uid}`;
//     }

//     return '/';
//   },
// };

module.exports = ({ node, key, value }) => (doc) => {
  console.log(doc);
  // if(doc.data.home_page){
  //   return "/"
  // }
  // else{
  //   return `/${doc.uid}`
  // }

  switch (doc.type) {
    case "projet":
      return `/projet/${doc.uid}`;

    default:
      if (doc.data.home_page) {
        return "/";
      } else {
        return `/${doc.uid}`;
      }
    // return "/";
  }
};
